import Image from 'next/image';
import { ReactNode } from 'react';

import AuthTestimonials, { SlideProps } from 'components/common/elements/AuthTestimonials';
import { useTranslation } from 'lib/hooks/useTranslation';
import SignupImage from 'public/images/signup-illustration.png';
import { Container } from '../Container';
import style from './AuthLayout.module.scss';

interface Props {
    contentRight: ReactNode;
    formWidth?: string;
    showCardNotice?: boolean;
}

export const AuthLayout = ({ contentRight, formWidth, showCardNotice = false }: Props) => {
    const t = useTranslation();

    const dummySlides: SlideProps[] = [
        {
            title: 'THE WORLD’S FIRST “PRIVACY SCORE”',
            subtitle: 'It’s like a credit score, but for privacy.',
            mainContent:
                'Our algorithm meticulously scours the Internet for privacy data about your company, and in just minutes, we generate a value from 100-900 so you can see how you stack up against industry benchmarks. No manual input needed.',
        },
        {
            title: 'RAISE YOUR REPUTATION',
            subtitle: 'Over 90% of global online users have concerns about data privacy.',
            mainContent: 'Safeguarding customer privacy is not just a protective measure—it’s a strategic opportunity for brand growth. ',
        },
        {
            title: 'DATA-DRIVEN SOLUTION',
            subtitle: 'The power of facts.',
            mainContent:
                'Ubiscore summarizes privacy risks and opportunities in 1 easy-to-understand objective dashboard. See exactly where information is getting shared without user consent, and get clear, effective risk mitigation strategies instantly.',
        },
    ];

    return (
        <Container>
            <div className={style.authLayout}>
                <div className={style.leftContainer}>
                    <div className={style.authTopDecoration}>
                        <Image src="/images/auth-top-decoration.svg" alt="auth top decoration" width={125} height={128} />
                    </div>
                    <div className={style.authTopDecoration2}>
                        <Image src="/images/auth-top-decoration-2.svg" alt="auth top decoration" width={78} height={78} />
                    </div>
                    <div className={style.sliderSlot}>
                        <AuthTestimonials slides={dummySlides} />
                    </div>
                    <div className={style.leftContainerContent}>
                        <Image src={SignupImage} alt="signup illustration" />
                    </div>
                </div>
                <div className={style.rightContainer}>
                    <div className={style.rightInner}>
                        <div className={style.rightInnerHeader}>
                            <Image width={153} height={32} src="/images/ubi-logo-form.png" alt="ubiscore logo" />
                            <p className={style.headerNotice}>{showCardNotice ? t.signup.tip : ''}</p>
                        </div>
                        <div className={style.form} style={{ width: formWidth }}>
                            {contentRight}
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};
