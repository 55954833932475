import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import cn from 'classnames';
import style from './AuthTestimonials.module.scss';

SwiperCore.use([Pagination, Navigation]);

export interface SlideProps {
    title: string;
    subtitle: string;
    mainContent: string;
}

const AuthSlide = ({ title, subtitle, mainContent }: SlideProps) => {
    return (
        <div className={style.slide}>
            <p className={style.slideTitle}>{title}</p>
            <p className={style.slideSubtitle}>{subtitle}</p>
            <p className={style.slideContent}>{mainContent}</p>
        </div>
    );
};

const AuthTestimonials = ({ slides }: { slides: SlideProps[] }) => {
    const pagination = {
        clickable: true,
        el: `.${style.customPagination}`,
        bulletActiveClass: style.customBulletActive,
        renderBullet: (index: number, className: string) => {
            return `<span class="${cn(className, style.customBullet)}"></span>`;
        },
    };

    return slides?.length ? (
        <div className={style.sliderContainer}>
            <Swiper slidesPerView={1} pagination={pagination} modules={[Pagination]} autoplay={true}>
                {slides.map((slide, index) => (
                    <SwiperSlide key={index}>
                        <AuthSlide {...slide} />
                    </SwiperSlide>
                ))}
                <div className={style.customPagination} />
            </Swiper>
        </div>
    ) : (
        <></>
    );
};

export default AuthTestimonials;
