export const getFormControlError = (formErrorTObject: Record<string, Record<string, string>>, fieldName: string, errorType: string | undefined) => {
    if (errorType) {
        return formErrorTObject?.[fieldName]?.[`${errorType}`];
    }
    return '';
};

export const mapExceptionToMessage = (formErrorTObject: Record<string, string>, exceptionMessage: string, defaultMessage: string) => {
    return formErrorTObject?.[exceptionMessage] || defaultMessage;
};
