import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputProps, TextField } from '@mui/material';
import { BaseInputProps } from 'components/common/sharedInterfaces/input.interfaces';
import { getFormControlError } from 'lib/utils/form';
import { useState } from 'react';
import { Controller } from 'react-hook-form';

interface Props extends BaseInputProps, InputProps {
    type?: 'text' | 'password' | 'email';
    name: string;
    readOnlyValue?: string | boolean | number;
}

export const UbiTextInput = ({
    autoFocus,
    name,
    control,
    label,
    rules,
    fieldError,
    classNames,
    multiline,
    type = 'text',
    formErrorTObject,
    disabled,
    inputProps,
    readOnly,
    readOnlyValue,
}: Props) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const inputPropsStyle = {
        sx: {
            height: multiline ? 'auto' : 70,
            fontSize: '1.8rem',
            '@media (max-width: 768px)': {
                height: 'auto',
                fontSize: '1.4rem',
            },
        },
        style: { borderRadius: 5 },
        endAdornment: type === 'password' && (
            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
        ),
    };

    const inputLabelStyle = {
        sx: {
            fontSize: '1.8rem',
            '@media (max-width: 768px)': {
                fontSize: '1.4rem',
            },
        },
        style: { color: '#B9BABD' },
    };

    const textareaSx = {
        width: '100%',
        '.MuiFormLabel-root[data-shrink=false]': {
            top: '5.5px',
            '@media (max-width: 768px)': {
                top: '0',
            },
        },
        '& .MuiOutlinedInput-root.Mui-disabled': {
            '& > fieldset': {
                border: '1px dashed #D6D8DC',
            },
        },
    };

    if (readOnly) {
        return (
            <TextField
                id="outlined-read-only-input"
                label={label}
                value={readOnlyValue}
                InputProps={{
                    ...inputPropsStyle,
                    readOnly: true,
                }}
                InputLabelProps={inputLabelStyle}
                sx={{ ...textareaSx }}
            />
        );
    }

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                    autoFocus={autoFocus}
                    multiline={multiline}
                    disabled={disabled}
                    InputProps={inputPropsStyle}
                    onBlur={onBlur}
                    inputProps={inputProps}
                    InputLabelProps={inputLabelStyle}
                    sx={textareaSx}
                    helperText={getFormControlError(formErrorTObject, name, fieldError?.type)}
                    error={!!fieldError}
                    value={value}
                    onChange={onChange}
                    name={name}
                    className={classNames}
                    type={showPassword ? 'text' : type}
                    id={name}
                    label={label}
                />
            )}
        />
    );
};

export default UbiTextInput;
