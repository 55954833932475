import { Button, ButtonProps } from '@mui/material';

interface IButtonProps extends ButtonProps {
    outlineColor?: string;
    hoverColor?: string;
    // this should be handled differently
    customStyles?: {
        width?: string | number;
        height?: string | number;
        fontSize?: string | number;
        fontWeight?: string | number;
        fontFamily?: string;
        borderRadius?: string;
        color?: string;
        background?: string;
    };
}

export const UbiButton = ({ variant = 'contained', outlineColor = '#000', customStyles, hoverColor, ...props }: IButtonProps) => {
    const buttonBaseStyle = {
        width: '100%',
        maxWidth: '100%',
        padding: '20px',
        height: '60px',
        // paddingBottom: '20px',
        fontFamily: "'Inter', sans-serif",
        fontSize: '1.8rem',
        fontWeight: 500,
        color: 'white',
        textTransform: 'none',
        transition: 'all 0.3s linear',
        '&:hover': { backgroundColor: hoverColor ?? '#2269C8' },
        '&:disabled': { backgroundColor: '#F7F8FA', color: '#fff', border: 'none' },
        whiteSpace: 'break-spaces',
    };

    const containedStyle = { fontWeight: 500, backgroundColor: '#4285DE' };

    const outlinedStyle = {
        border: `1px solid ${outlineColor ?? ' #B9B9B9'}`,
        backgroundColor: 'transparent',
        color: outlineColor ?? '#000000',
        '&:hover': { fontWeight: 400, opacity: 0.8 },
    };

    return (
        <Button
            type="submit"
            {...props}
            sx={{
                ...buttonBaseStyle,
                ...(variant === 'contained' ? containedStyle : outlinedStyle),
                ...customStyles,
            }}>
            {props.children}
        </Button>
    );
};
