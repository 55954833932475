import { DEFAULT_LOCALE } from 'constants/locales';
import { useRouter } from 'next/router';

export const useNavigationHelpers = () => {
    const router = useRouter();

    /**
     *
     * @param route string route with leading "/"
     * @returns router localized route
     */
    const getLocalizedRoute = (route: string) => {
        if (!route?.length) {
            return '';
        }

        const localeString = router?.locale === DEFAULT_LOCALE ? '' : router?.locale;

        if (!localeString) {
            return route;
        }
        return `/${localeString}${route}`;
    };

    return {
        getLocalizedRoute,
    };
};
